import React from 'react';

import genericDeskImage from '../../../../assets/img/products/desk_generic.jpg';

import { ParallaxComponent } from '../../../common';

import './styles.styl';

const CLASS_ARRAY = ['left', 'middle'];

const ProductImages = ({ images, hidden }) => (
  <section
    id="product-images"
    className={`product-images ${hidden ? 'hidden' : ''}`}
  >
    {images.map((href, index) => (
      <ParallaxComponent index={!(index % 2) ? 12 : 25} key={href}>
        <img
          src={href}
          alt=""
          className={`situations-pictures ${
            CLASS_ARRAY[index] ? CLASS_ARRAY[index] : ''
          }`}
        />
      </ParallaxComponent>
    ))}
    <ParallaxComponent index={12}>
      <img
        src={genericDeskImage}
        alt=""
        className="situations-pictures right"
      />
    </ParallaxComponent>
  </section>
);

export default ProductImages;
