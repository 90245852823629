import React, { Component } from 'react';

export default class QuantitySelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedQuantity: props.initialValue,
    };
  }

  componentDidUpdate(prevProps) {
    const { initialValue } = this.props;

    if (prevProps.initialValue !== initialValue) {
      // Update state when value props change
      this.setState({ selectedQuantity: initialValue });
    }
  }

  incrementSelectedQuantity(increment) {
    const { min, max, onQuantityChange } = this.props;
    const { selectedQuantity } = this.state;

    const nextQuantity = selectedQuantity + increment;

    if (nextQuantity >= min && nextQuantity <= max) {
      this.setState({
        selectedQuantity: nextQuantity,
      });
      if (onQuantityChange) {
        onQuantityChange(nextQuantity);
      }
    }
  }

  render() {
    const { selectedQuantity } = this.state;

    return (
      <div className="quantity-selector">
        <div className="number">
          <button
            type="button"
            className="remove"
            onClick={() => this.incrementSelectedQuantity(-1)}
          >
            -
          </button>
          <button
            type="button"
            className="add"
            onClick={() => this.incrementSelectedQuantity(1)}
          >
            +
          </button>
          <div className="value">
            <span className="current">{selectedQuantity}</span>
          </div>
        </div>
      </div>
    );
  }
}
