import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import QuantitySelector from './quantitySelector';
import './styles.styl';

import {
  addToCartAnim,
  addToCartAnimMobile,
} from '../../../../utils/addToCartAnim';
import { isMobile as isMobileChecker } from '../../../../utils/layout';

export default class ProductSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: 1,
      isMobile: false,
    };
  }

  componentDidMount() {
    this.setState({ isMobile: isMobileChecker() });
  }

  componentDidUpdate(prevProps) {
    const { hidden } = this.props;
    if (prevProps.hidden !== hidden) {
      this.setState({ quantity: 1 });
    }
  }

  renderTitle() {
    const {
      product: { name },
    } = this.props;

    // The first word on the first line, the rest on the second line
    const splitName = name.split(' ');
    const firstLine = splitName[0];
    const secondLine = splitName.slice(1).join(' ');

    return (
      <div className="title-wrapper">
        <h1>{firstLine}</h1>
        <h2>{secondLine}</h2>
      </div>
    );
  }

  renderImage() {
    const {
      product: { mainImage },
      mainImageRef,
    } = this.props;

    return (
      <div className="left">
        <div className="overlay-white" />
        <div className="box-container up">
          <div id="up-anim" />
        </div>
        <img
          ref={mainImageRef}
          src={mainImage}
          alt=""
          className="monimalz monimalz-jump"
        />
        <div className="box-container down">
          <div id="down-anim" />
        </div>
      </div>
    );
  }

  addToCartAnimation = async () => {
    const {
      addToCart,
      product: { uuid },
    } = this.props;
    const { isMobile, quantity } = this.state;

    isMobile ? await addToCartAnimMobile() : await addToCartAnim();
    addToCart(uuid, quantity);
  };

  renderAddToCartButton() {
    const { addToCart, wordings, product } = this.props;
    const { quantity } = this.state;

    return (
      <button
        className="button add-to-cart"
        onClick={this.addToCartAnimation}
        type="button"
      >
        <p>{wordings.add_to_cart}</p>
      </button>
    );
  }

  renderCommonInfo() {
    const { wordings } = this.props;
    // TODO : clean
    return (
      <Fragment>
        <p style={{ marginTop: '30px', fontSize: '.8em' }}>{wordings.size}</p>
        {/* <a style={{ fontSize: '.8em' }} href="" className="share">
          {wordings.share}
        </a> */}
      </Fragment>
    );
  }

  renderInfo() {
    const { product, wordings } = this.props;
    const { quantity } = this.state;
    // TODO: price
    return (
      <div className="right">
        <div className="product-infos">
          <p className="product-description">{product.description}</p>
          <p>
            <span className="price-tag">
              {product.price ? product.formatted : ''}
            </span>
          </p>
          {/* {product.slug !== 'mona-la-baleine' ? (
            <>
              <QuantitySelector
                min={1}
                max={5}
                initialValue={quantity}
                onQuantityChange={newQuantity =>
                  this.setState({ quantity: newQuantity })
                }
              />
              {this.renderAddToCartButton()}
            </>
          ) : ( */}
          <p className="product-out-of-stock">{wordings.out_of_stock}</p>
          {/* )} */}
          {this.renderCommonInfo()}
        </div>
      </div>
    );
  }

  renderContent() {
    return (
      <div className="content-wrapper">
        {this.renderImage()}
        {this.renderInfo()}
      </div>
    );
  }

  render() {
    const { hidden } = this.props;

    return (
      <section
        id="product-section"
        className={`product ${hidden ? 'hidden' : ''}`}
      >
        {this.renderTitle()}
        {this.renderContent()}
      </section>
    );
  }
}

ProductSection.propTypes = {
  wordings: PropTypes.shape({}).isRequired,
  addToCart: PropTypes.func.isRequired,
  product: PropTypes.shape({}).isRequired,
  hidden: PropTypes.bool.isRequired,
  mainImageRef: PropTypes.shape({}).isRequired,
};
