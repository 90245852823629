import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { TweenMax, Power4 } from 'gsap';

import { selectMonimalz } from '../../actions/appActions';
import { requestAddToCart } from '../../actions/shopActions';

import ProductList from './productList';
import ProductDetails from './productDetails';

class ProductTunnel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productListHidden: Boolean(props.selectedMonimalz),
      productDetailsHidden: Boolean(!props.selectedMonimalz),
      animatedImageSrc: '',
      animatedImageStyle: {},
      animated: false,
    };

    this.mainImageRef = React.createRef();
    this.animatedImage = React.createRef();
    this.setupReferences(props.monimalzs);
  }

  componentWillUpdate(nextProps) {
    if (nextProps.monimalzs !== this.props.monimalzs) {
      this.setupReferences(nextProps.monimalzs);
    }

    if (
      nextProps.selectedMonimalz !== this.props.selectedMonimalz &&
      nextProps.selectedMonimalz
    ) {
      this.setupAnimation(nextProps.selectedMonimalz);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.animated !== this.state.animated && this.state.animated) {
      this.startAnimation();
    }
  }

  setupReferences(monimalzs = []) {
    this.productListRef = {};
    monimalzs.forEach(({ id }) => {
      this.productListRef[id] = React.createRef();
    });
  }

  setupAnimation(selectedMonimalz) {
    if (this.props.isMobile) {
      // TODO: Improve
      window.scrollTo(0, 0);
      return this.setState({
        productListHidden: true,
        productDetailsHidden: false,
      });
    }

    const initialBox = this.productListRef[
      selectedMonimalz
    ].current.getBoundingClientRect();
    // Setup image style
    const imageStyle = {
      width: `${initialBox.width}px`,
      height: `${initialBox.height}px`,
      top: `${initialBox.top}px`,
      left: `${initialBox.left}px`,
      zIndex: 1000,
      display: 'block',
      position: 'fixed',
    };

    this.setState({
      productDetailsHidden: true,
      animatedImageSrc: this.productListRef[selectedMonimalz].current.src,
      animatedImageStyle: imageStyle,
      animated: true,
      productListHidden: true,
    });
  }

  startAnimation() {
    const targetBox = this.mainImageRef.current.getBoundingClientRect();
    TweenMax.to(this.animatedImage.current, 1, {
      width: targetBox.width,
      height: targetBox.height,
      top: targetBox.top,
      left: targetBox.left,
      ease: Power4.easeInOut,
      onComplete: () => {
        this.setState({
          productDetailsHidden: false,
          animatedImageStyle: {},
          animated: false,
          animatedImageSrc: '',
        });
      },
    });
  }

  renderAnimatedImage() {
    const { animatedImageStyle, animatedImageSrc } = this.state;

    return (
      <img
        alt=""
        src={animatedImageSrc}
        style={animatedImageStyle}
        ref={this.animatedImage}
      />
    );
  }

  renderProductDetails() {
    const {
      selectedMonimalz,
      requestAddToCart,
      products,
      accessories,
    } = this.props;
    const { productDetailsHidden, animated } = this.state;

    return (
      <ProductDetails
        mainImageRef={this.mainImageRef}
        requestAddToCart={requestAddToCart}
        product={products[selectedMonimalz]}
        products={products}
        accessories={accessories}
        hidden={productDetailsHidden}
        lock={productDetailsHidden && !animated}
        backToList={() => {
          this.props.selectMonimalz('');
          this.setState({
            productListHidden: false,
            productDetailsHidden: true,
          });
        }}
      />
    );
  }

  renderProductList() {
    const { selectedMonimalz } = this.props;
    const { productListHidden, productDetailsHidden } = this.state;

    return (
      <ProductList
        selectedMonimalz={selectedMonimalz}
        onProductClick={this.props.selectMonimalz}
        close={productListHidden && !productDetailsHidden}
        animated={productListHidden}
        itemRefs={this.productListRef}
      />
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderProductList()}
        {this.renderProductDetails()}
        {this.renderAnimatedImage()}
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  app: { selectedMonimalz, isMobile },
  shop: { products, accessories, monimalzs },
}) => ({
  isMobile,
  selectedMonimalz,
  products,
  accessories,
  monimalzs,
});

const connectedProductTunnel = connect(
  mapStateToProps,
  { selectMonimalz, requestAddToCart }
)(ProductTunnel);

export default connectedProductTunnel;
