/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { requestMonimalzProducts } from '../actions/shopActions';

import Layout from '../components/layout/layout';
import {
  NavBar,
  Menu,
  Footer,
  Basket,
  pageWithNavigation,
} from '../components/common';
import ProductTunnel from '../components/product';

class ProductPage extends Component {
  componentDidMount() {
    this.props.requestMonimalzProducts();
  }

  render() {
    const {
      data: {
        wordings: {
          fr: { product },
        },
      },
    } = this.props;

    return (
      <Layout title={product.page_title} description={product.description}>
        <NavBar withLogo basketClassName="yellow" />
        <Basket />
        <Menu />
        <ProductTunnel />
        <Footer />
      </Layout>
    );
  }
}

export const query = graphql`
  query ProductPageQuery {
    wordings {
      fr {
        product {
          page_title
          description
        }
      }
    }
  }
`;

ProductPage.propTypes = {
  requestMonimalzProducts: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
};

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  { requestMonimalzProducts }
)(pageWithNavigation(ProductPage));
