import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './styles.styl';

const TITLE_SPLIT_INFOS = [
  { length: 5, size: '80px' },
  { length: 1, size: '60px' },
  { length: 1, size: '40px' },
  { length: 1, size: '30px' },
  { length: 1, size: '20px' },
  { length: 1, size: '15px' },
  { length: 1, size: '10px' },
];

export default class ProductParts extends Component {
  renderTitle() {
    const {
      wordings: { parts_title = '' },
    } = this.props;
    let curInd = 0;

    return (
      <h1>
        {TITLE_SPLIT_INFOS.map(({ length, size }) => {
          const result = (
            <span key={curInd} style={{ fontSize: size }}>
              {parts_title.substr(curInd, length)}
            </span>
          );
          curInd += length;
          return result;
        })}
      </h1>
    );
  }

  renderPartList() {
    const { parts } = this.props;

    if (!parts) {
      return;
    }

    const splitParts = parts.split('\n');
    const nbAlign = 3 - (splitParts.length % 3);
    const alignLi = [];

    for (let i = nbAlign; nbAlign !== 3 && i > 0; i -= 1) {
      alignLi.push(<li key={i}>&nbsp;</li>);
    }

    return (
      <ul className="parts-list">
        {splitParts.map((part, index) => (
          <li key={`${part}${index}`}>{part}</li>
        ))}
        {alignLi}
      </ul>
    );
  }

  render() {
    const { wordings, image, hidden } = this.props;

    if (hidden) {
      return null;
    }

    return (
      <section id="product-parts" className="product-parts">
        {this.renderTitle()}
        <h2>{wordings.parts_subtitle}</h2>

        <div className="parts">
          <img src={image} alt="" className="parts-cover" />
          {this.renderPartList()}
        </div>
      </section>
    );
  }
}

ProductParts.defaultProps = {
  image: '',
  parts: '',
};

ProductParts.propTypes = {
  wordings: PropTypes.shape({}).isRequired,
  image: PropTypes.string,
  parts: PropTypes.string,
  hidden: PropTypes.bool.isRequired,
};
