import React from 'react';
import PropTypes from 'prop-types';

import './styles.styl';

// TODO: wordings
const ProductNav = ({ wordings, onClickList, onAnchorClick }) => (
  <div className="product-details-nav">
    <ul>
      <li>
        <button type="button" onClick={onClickList}>
          {wordings.link_other}
        </button>
      </li>
      <li>
        <button type="button" onClick={() => onAnchorClick('product')}>
          {wordings.link_product}
        </button>
      </li>
      <li>
        <button type="button" onClick={() => onAnchorClick('images')}>
          {wordings.link_photos}
        </button>
      </li>
      <li>
        <button type="button" onClick={() => onAnchorClick('parts')}>
          {wordings.link_box}
        </button>
      </li>
    </ul>
  </div>
);

ProductNav.propTypes = {
  wordings: PropTypes.shape().isRequired,
  onClickList: PropTypes.func.isRequired,
  onAnchorClick: PropTypes.func.isRequired,
};

export default ProductNav;
