import React from 'react';
import PropTypes from 'prop-types';

import TooMuchSuccess from '../../../assets/img/products/tropdesucces.png';

const ProductListItem = React.forwardRef(
  (
    {
      product: { name, mainImage, uuid: id },
      onClick,
      transparent,
      onPointerEnter,
      onPointerLeave,
      hidden,
      outOfStock,
    },
    ref
  ) => (
    <li
      className={`item ${transparent ? 'transparent' : ''} ${
        hidden ? 'hidden' : ''
      }`}
      onClick={() => onClick(id)}
      role="button"
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
    >
      <div className="item-wrapper">
        <img src={mainImage} alt={name} ref={ref} />
        {outOfStock && (
          <img
            className="product-img-out-of-stock"
            src={TooMuchSuccess}
            alt="out of stock"
            style={{
              position: 'absolute',
              right: 0,
              width: '35%',
            }}
          />
        )}
        <p className="item-name">{name}</p>
      </div>
    </li>
  )
);

ProductListItem.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    mainImage: PropTypes.string,
    slug: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  transparent: PropTypes.bool.isRequired,
  onPointerEnter: PropTypes.func.isRequired,
  onPointerLeave: PropTypes.func.isRequired,
  outOfStock: PropTypes.bool.isRequired,
};

export default ProductListItem;
