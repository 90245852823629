import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { scrollTo } from '../../../actions/appActions';

import ProductNav from './productNav/productNav';
import ProductSection from './productSection/productSection';
import ProductImages from './productImages/productImages';
import ProductParts from './productParts/productParts';

import AnchorTarget from './anchorTarget';

import defaultImage from '../../../assets/img/products/Mouki.jpg';

import './styles.styl';
// TODO: hide or show

class ProductDetails extends Component {
  constructor(props) {
    super(props);

    this.references = {
      product: React.createRef(),
      images: React.createRef(),
      parts: React.createRef(),
    };

    this.onAnchorClick = this.onAnchorClick.bind(this);
  }

  onAnchorClick(key) {
    if (this.references[key] && this.references[key].current) {
      const to = this.references[key].current.offsetTop;
      this.props.scrollTo(to);
    }
  }

  render() {
    const {
      requestAddToCart,
      product,
      wordings,
      backToList,
      hidden,
      lock,
      mainImageRef,
    } = this.props;

    return (
      <div className={`product-details ${lock ? 'lock' : ''}`}>
        <ProductNav
          onClickList={backToList}
          wordings={wordings}
          onAnchorClick={this.onAnchorClick}
        />
        <AnchorTarget ref={this.references.product}>
          <ProductSection
            addToCart={requestAddToCart}
            product={product}
            wordings={wordings}
            hidden={hidden}
            mainImageRef={mainImageRef}
          />
        </AnchorTarget>
        <AnchorTarget ref={this.references.images}>
          <ProductImages images={product.images} />
        </AnchorTarget>
        <AnchorTarget ref={this.references.parts}>
          <ProductParts
            hidden={hidden}
            image={product.parts}
            wordings={wordings}
            parts={wordings[`parts_${product.slug.replace(/-/g, '_')}_list`]}
          />
        </AnchorTarget>
      </div>
    );
  }
}

ProductDetails.propTypes = {
  requestAddToCart: PropTypes.func.isRequired,
  product: PropTypes.shape({}),
  wordings: PropTypes.shape({}).isRequired,
  backToList: PropTypes.func.isRequired,
  mainImageRef: PropTypes.shape({}).isRequired,
};

ProductDetails.defaultProps = {
  product: {
    mainImage: defaultImage,
    name: '',
    description: '',
    images: [],
    sku: '',
    slug: '',
  },
};

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  { scrollTo }
)(ProductDetails);
