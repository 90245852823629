import React from 'react';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';

import ProductListComponent from './productList';

const ProductList = props => (
  <StaticQuery
    render={({
      wordings: {
        fr: { product },
      },
    }) => <ProductListComponent {...props} wordings={{ ...product }} />}
    query={graphql`
      query ProductList {
        wordings {
          fr {
            product {
              list_title_line1
              list_title_line2
              list_subtitle
            }
          }
        }
      }
    `}
  />
);

const mapStateToProps = ({ shop: { products, monimalzs } }) => ({
  products,
  monimalzs,
});

const connectedProductList = connect(mapStateToProps)(ProductList);

export default connectedProductList;
