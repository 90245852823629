import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import ProductDetailsComponent from './productDetails';

const ProductDetails = props => (
  <StaticQuery
    render={({
      wordings: {
        fr: { product },
      },
    }) => <ProductDetailsComponent {...props} wordings={{ ...product }} />}
    query={graphql`
      query ProductDetails {
        wordings {
          fr {
            product {
              list_title_line1
              list_title_line2
              list_subtitle
              parts_title
              parts_subtitle
              add_to_cart
              share
              size
              add_to_cart
              accessories_title
              link_other
              link_product
              link_photos
              link_box
              link_accessoires
              parts_miko_le_panda_list
              parts_mouki_le_singe_list
              parts_mona_la_baleine_list
              out_of_stock
            }
          }
        }
      }
    `}
  />
);

export default ProductDetails;
