import { TimelineLite, ScrollToPlugin } from "gsap/all";
import lottie from 'lottie-web';

const plugins = [ScrollToPlugin];

export const addToCartAnim = () =>
  new Promise(res => {

    const animUp = lottie.loadAnimation({
      container: document.getElementById('up-anim'),
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/animations/carton_up.json',
    });
    const animDown = lottie.loadAnimation({
      container: document.getElementById('down-anim'),
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/animations/carton_down.json',
    });

    const tl = new TimelineLite();
    tl.to(
        'html',
        0.01, {
          overflowY: 'hidden',
        }
      )
    .to(
        window, 
        0.5, {
          scrollTo: {
            y: 0
          },
          ease: Power1.easeOut
        }, 
        'initialize'
      )
      .to(
        '.box-container',
        0.01, {
          visibility: 'visible',
        },
        'initialize'
      )
      .call(() => {
        animUp.play();
        animDown.play();
      })
      .to(
        '.overlay-white',
        0.1, {
          zIndex: 10,
          opacity: 0.8,
        },
        'initialize'
      )
      .to(
        'body',
        0.1, {
          height: '100vh',
        }
      )
      .fromTo(
        '.box-container',
        0.1, {
          position: 'fixed',
          left: 'calc(50% + 0px)',
          x: '-50%',
          bottom: 'calc(0% - 20px)',
        }, {
          bottom: 'calc(0% + 280px)',
          ease: Power1.easeIn,
        },
        'start'
      )
      .to(
        '.monimalz-jump',
        0.2, {
          position: 'absolute',
          top: '-35%',
          left: '50%',
        },
        '+=0.5'
      )
      .to(
        '.monimalz-jump',
        0.3, {
          scale: 0.4,
        },
        '-=0.2'
      )
      .to(
        '.monimalz-jump',
        0.3, {
          top: '120%',
          left: '95%',
          x: '-50%',
        },
        '-=0.1'
      )
      .to(
        '.monimalz-jump',
        0.1, {
          opacity: 0,
        },
        '+=0.5'
      )
      .to(
        '.monimalz-jump',
        0.1, {
          left: '-100%',
          top: 0,
          transform: 'unset',
        },
        '+=0.5'
      )
      .to(
        '.box-container',
        0.6, {
          left: 'calc(100% - 190px)',
          bottom: 'calc(100% + 120px)',
          x: 0,
          ease: Power2.easeIn,
        },
        '+=2.5'
      )
      .call(() => {
        animUp.destroy();
        animDown.destroy();
        res();
      })
      .to('.plus-bounce', 0.1, {
        scale: 0.8,
      })
      .to('.plus-bounce', 0.1, {
        scale: 1.2,
        ease: Bounce.easeOut,
      })
      .to('.plus-bounce', 0.1, {
        scale: 1,
      })
      .to(
        'html',
        0.01, {
          overflowY: 'visible',
        }
      )
      .to(
        '.box-container',
        0.1, {
          visibility: 'hidden',
          position: 'fixed',
          left: '50%',
          x: '-50%',
          bottom: '20%',
        },
        '+=0.2'
      )
      .to('.overlay-white', 0.1, {
        zIndex: -10,
        opacity: 0,
      })
      .to('body', 0.1, {
        height: 'auto',
      })
      .to(
        '.box-container',
        0.1, {
          position: 'fixed',
          left: '50%',
          x: '-50%',
          bottom: '0px',
        },
        '+=0.2'
      )
      .to(
        '.monimalz-jump',
        0.3, {
          opacity: 1,
          left: '23%',
          ease: Power4.easeOut,
        },
        '+=0.2'
      );
  });

export const addToCartAnimMobile = () =>
  new Promise(res => {
    const animUp = lottie.loadAnimation({
      container: document.getElementById('up-anim'),
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/animations/carton_up.json',
    });
    const animDown = lottie.loadAnimation({
      container: document.getElementById('down-anim'),
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/animations/carton_down.json',
    });

    animUp.play();
    animDown.play();

    let tl = new TimelineLite();
    tl.to(
        'html',
        0.01, {
          overflowY: 'hidden',
        }
      )
    .to(
        window, 
        0.5, {
          scrollTo: {
            y: 0
          },
          ease: Power1.easeOut
        }, 
        'initialize'
      )
      .to(
        '.box-container',
        0.01, {
          visibility: 'visible',
        },
        'initialize'
      )
      .to(
        '.overlay-white',
        0.1, {
          zIndex: 10,
          opacity: 0.8,
        },
        'initialize'
      )
      .to(
        'body',
        0.1, {
          height: '100vh',
        }
      )
      .fromTo(
        '.box-container',
        0.1, {
          position: 'fixed',
          left: 'calc(50% + 0px)',
          x: '-50%',
          bottom: 'calc(0% - 20px)',
        }, {
          bottom: 'calc(0% + 280px)',
          ease: Power1.easeIn,
        },
        'start'
      )
      .to(
        '.monimalz-jump',
        0.2, {
          position: 'absolute',
          top: '-50px',
          left: '15%',
        },
        '+=0.5'
      )
      .to(
        '.monimalz-jump',
        0.3, {
          top: '460px',
          left: '29%',
          x: '-50%',
        },
        '-=0.1'
      )
      .to(
        '.monimalz-jump',
        0.3, {
          scale: 0.4,
        },
        '-=0.3'
      )
      .to(
        '.monimalz-jump',
        0.1, {
          opacity: 0,
        },
        '+=0.5'
      )
      .to(
        '.monimalz-jump',
        0.1, {
          left: '-100%',
          top: 0,
          transform: 'unset',
        },
        '+=0.5'
      )
      .to(
        '.box-container',
        0.6, {
          left: 'calc(100% - 190px)',
          bottom: 'calc(100% + 120px)',
          x: 0,
          ease: Power2.easeIn,
        },
        '+=2.1'
      )
      .call(() => {
        animUp.destroy();
        animDown.destroy();
        res();
      })
      .to('.plus-bounce', 0.1, {
        scale: 0.8,
      })
      .to('.plus-bounce', 0.1, {
        scale: 1.2,
        ease: Bounce.easeOut,
      })
      .to('.plus-bounce', 0.1, {
        scale: 1,
      })
      .to(
        'html',
        0.01, {
          overflowY: 'visible',
        }
      )
      .to(
        '.box-container',
        0.1, {
          visibility: 'hidden',
          position: 'fixed',
          left: '50%',
          x: '-50%',
          bottom: '20%',
        },
        '+=0.2'
      )
      .to('.overlay-white', 0.1, {
        zIndex: -10,
        opacity: 0,
      })
      .to('body', 0.1, {
        height: 'auto',
      })
      .to(
        '.monimalz-jump',
        0.3, {
          opacity: 1,
          left: '16%',
          ease: Power4.easeOut,
        },
        '+=0.2'
      );
  });