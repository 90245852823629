/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProductListItem from './productListItem';

import './styles.styl';

class ProductList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoveredMonimalz: '',
    };
  }

  renderItems() {
    const {
      monimalzs,
      products,
      onProductClick,
      selectedMonimalz,
      itemRefs,
      animated,
    } = this.props;

    const { hoveredMonimalz } = this.state;
    return (
      <div className="items-wrapper">
        <ul>
          {monimalzs.reduce((acc, { id }) => {
            if (products[id]) {
              acc.push(
                <ProductListItem
                  key={id}
                  ref={itemRefs[id]}
                  product={products[id]}
                  onClick={onProductClick}
                  hidden={
                    animated || (selectedMonimalz && selectedMonimalz !== id)
                  }
                  transparent={Boolean(
                    hoveredMonimalz && id !== hoveredMonimalz
                  )}
                  onPointerEnter={() => {
                    this.setState({ hoveredMonimalz: id });
                  }}
                  onPointerLeave={() => {
                    this.setState({ hoveredMonimalz: '' });
                  }}
                  outOfStock={true}
                />
              );
            }
            return acc;
          }, [])}
        </ul>
      </div>
    );
  }

  render() {
    const {
      close,
      animated,
      wordings: { list_title_line1, list_title_line2, list_subtitle },
    } = this.props;

    return (
      <div
        className={`product-tunnel ${close ? 'close' : ''} ${
          animated ? 'hidden' : ''
        }`}
      >
        <div className="title-wrapper">
          <h2>{list_title_line1}</h2>
          <h1>{list_title_line2}</h1>
          <p>{list_subtitle}</p>
        </div>
        {this.renderItems()}
      </div>
    );
  }
}

ProductList.defaultProps = {
  selectedMonimalz: '',
};

ProductList.propTypes = {
  products: PropTypes.shape({}).isRequired,
  selectedMonimalz: PropTypes.string,
  monimalzs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onProductClick: PropTypes.func.isRequired,
  wordings: PropTypes.shape({}).isRequired,
};

export default ProductList;
